<template>
  <div class="solution">
    <div class="solution-container">
      <Title title="解決方案" />
      <van-swipe class="solution-swipe" :loop="false" indicator-color="#999" :width="120" :height="150">
        <van-swipe-item>
          <img src="@/assets/images/solution-prod01.png" alt="">
          <p>线束线缆</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod02.png" alt="">
          <p>电路板</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod03.png" alt="">
          <p>PCBA</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod04.png" alt="">
          <p>鈑金</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod05.png" alt="">
          <p>铜排／母排</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod06.png" alt="">
          <p>电气柜组装</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod07.png" alt="">
          <p>注塑件</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/solution-prod08.png" alt="">
          <p>辅材配件</p>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import Title from '../../components/Title.vue'
export default {
  components: { Title }
}
</script>

<style scoped>
.solution {
  padding: 30px 20px 16px;
}
.solution-swipe .van-swipe-item {
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}
.solution-swipe .van-swipe-item + .van-swipe-item {
  margin-left: 12px;
}

.solution-swipe .van-swipe-item img {
  width: 80%;
}
</style>