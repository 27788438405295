<template>
  <div class="desc">
    <div class="desc-container">
        <div class="desc-content">
          <p>e-Maker Solutions 易代工网络科技成立于2019年，是一家由全球工业隐形冠军企业 \ 工业互联网资深专家共同参与创立、聚焦工业定制领域的产业数字化服务商。</p>
          <p>eMaker关注客户的挑战与压力，基于数字中台技术，为中小制造业提供有适用、好用、实惠的一站式数字化解决方案和配套工业服务，帮助企业通过实施全面数智化管理，实现企业的质量、效率和动力变革，助力成为专精特新小巨人企业，开创事业发展新格局。</p>
        </div>
     </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .desc .desc-container {
    background-image: url('~@/assets/images/description-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .desc .desc-container .desc-content {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 30px 20px 0px;
  }

  .desc .desc-container  p {
    color: #333;
    line-height: 2;
    font-size: 14px;
    font-weight: 400;
  }

  .desc .desc-container  p + p {
    margin-top: 16px;
  }
</style>