<template>
  <div class="company-value">
    <div class="company-value-container">
      <Title title="eMaker Solutions" />
      <ul>
        <li>
          <h5>我们的愿景</h5>
          <p>链接每一位合作伙伴的优势和天赋，共筑生机／和谐的智能制造生态系统，让工作和生活更美好。</p>
        </li>
        <li>
          <h5>我们的使命</h5>
          <p>聚焦客户关注的挑战与压力，提供有竞争力的数字化解决方案和工业配套服务，持续为客户创造最大价值。</p>
        </li>
        <li>
          <h5>我们的价值观</h5>
          <p>以成就客户为先，以价值创造者为本，坚持开放协作，持续追求卓越。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from '../../components/Title.vue'
export default {
  components: { Title }
}
</script>
s
<style scoped>
.company-value {
  padding: 30px 20px;
  background-color: rgb(245, 247, 249);
}


.company-value .company-value-container ul li + li {
  margin-top: 20px;
}

.company-value .company-value-container ul li h5 {
  font-size: 16px;
  font-weight: bold;
}

.company-value .company-value-container ul li p {
  font-size: 14px;
  line-height: 1.8;
  margin-top: 16px;
  font-weight: normal;
  color: #666;
}
</style>