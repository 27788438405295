<template>
  <div class="instry" id="industry">
    <div class="instry-container">
      <Title title="工业服务" />
      <el-row :gutter="8">
        <el-col :span="12">
          <div>
            <img src="@/assets/images/industry-serve-icon01.png" alt="" />
            <p>数字转型陪跑</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/industry-serve-icon02.png" alt="" />
            <p>数字员工服务</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/industry-serve-icon03.png" alt="" />
            <p>数字工厂服务</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/industry-serve-icon04.png" alt="" />
            <p>数据治理服务</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Title from '../../components/Title.vue'
export default {
  components: { Title }
}
</script>

<style scoped>
.instry {
  padding: 30px 20px;
  background-color: rgb(245, 247, 249);
}

.instry .instry-container .el-row .el-col {
  height: 150px;
  margin-top: 8px;
  background-color: #eee;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-clip: content-box;
  text-align: center;
  color: #fff;
}

.instry .instry-container .el-row .el-col > div {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.1),  rgba(0, 0, 0, 0));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instry .instry-container .el-row .el-col:hover > div {
    background-color: rgba(79, 209, 233, 0.75);
    background-image: none,
}

.instry .instry-container .el-row .el-col img {
    width: 58px;
    height: 58px;
    filter:invert(1);
}

.instry .instry-container .el-row .el-col p {
    font-size: 15px;
    font-weight: bold;
    margin-top: 32px;
}

.instry .instry-container .el-row .el-col:nth-child(1) {
    background-image: url('~@/assets/images/industry-bg-01.jpg');
}
.instry .instry-container .el-row .el-col:nth-child(2) {
    background-image: url('~@/assets/images/industry-bg-02.jpg');
}
.instry .instry-container .el-row .el-col:nth-child(3) {
    background-image: url('~@/assets/images/industry-bg-03.jpg');
}
.instry .instry-container .el-row .el-col:nth-child(4) {
    background-image: url('~@/assets/images/industry-bg-04.jpg');
}

</style>