<template>
  <div class="m-home">
    <Swipe></Swipe>
    <Description></Description>
    <DigProd></DigProd>
    <IndstryServe></IndstryServe>
    <CompanyArch></CompanyArch>
    <ServeTrade></ServeTrade>
    <Solution></Solution>
    <CompanyVlaue></CompanyVlaue>
    <Contact></Contact>  
    <Brand></Brand> 
  </div>
</template>

<script>
import Swipe from './swiper/index.vue'
import Description from './description/index.vue'
import DigProd from './digitalProd/index.vue'
import IndstryServe from './indstryServe/index.vue'
import CompanyArch from './companyArch/index.vue'
import ServeTrade from './serveTrade/index.vue'
import Solution from './solution/index.vue'
import CompanyVlaue from './companyValue/index.vue'
import Brand from './Brand/index.vue'
import Contact from './Contact/index.vue'
export default {
  name:'Home',
  components:{ 
    Swipe, 
    Description, 
    DigProd, 
    IndstryServe, 
    CompanyArch, 
    ServeTrade, 
    Solution, 
    CompanyVlaue,
    Brand,
    Contact
  }
}
</script>

<style scoped>
  
</style>