<template>
  <div class="arch">
    <div class="arch-container">
        <div class="title-con">
            <div class="title">
                <p>微粒化解耦中台架构</p>
                <p>一站式数字化解决方案</p>
            </div>
        </div>
        <div>
            <img src="@/assets/images/architecture2.jpg" alt=""/>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    
}
</script>

<style scoped>
    .arch .arch-container {
        padding: 30px 20px;
    }

    .arch .title{
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
 }
    .arch .arch-container div img {
        width: 100%;
    }
</style>