<template>
  <div class="trade">
    <div class="trade-container">
      <Title title="服务行业" />
      <van-swipe class="serve-swipe" :loop="false" indicator-color="#999" :width="95" :height="150">
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon01.png" alt="">
          <p>工业自动化</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon02.png" alt="">
          <p>新能源</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon03.png" alt="">
          <p>医疗</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon04.png" alt="">
          <p>通讯</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon05.png" alt="">
          <p>汽车</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon06.png" alt="">
          <p>轨道交通</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon07.png" alt="">
          <p>输配电</p>
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/serve-trade-icon08.png" alt="">
          <p>人工智能</p>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import Title from '../../components/Title.vue'
export default {
  name: 'trade',
  components: { Title }

}
</script>

<style scoped>
.trade {
  margin: 0;
  padding: 30px 20px 16px;
  background-color: rgb(245, 247, 249);
}

.serve-swipe .van-swipe-item {
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

.serve-swipe .van-swipe-item img {
  width: 100%;
}
</style>