<template>
  <div class="digt-prod" id="digtProd">
    <div class="digt-prod-container">
      <Title title="数字产品" />
      <el-row class="digt-prod-content" :gutter="8">
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon01.png" alt="" />
            <p>iBPM</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon02.png" alt="" />
            <p>数字驾驶舱</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon03.png" alt="" />
            <p>数字工程</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon04.png" alt="" />
            <p>数字供应链</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon05.png" alt="" />
            <p>数字QMS</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <img src="@/assets/images/digt-prod-icon06.png" alt="" />
            <p>数字OKR</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Title from '../../components/Title.vue'
export default {
  components: { Title },
}
</script>

<style scoped>
.digt-prod {
  padding: 30px 20px;
}

.digt-prod .digt-prod-container .el-row .el-col {
  height: 150px;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-clip: content-box;
  text-align: center;
  color: #fff;
}

.digt-prod .digt-prod-container .el-row .el-col:hover > div {
  background-color: rgba(79, 209, 233, 0.65);
}

.digt-prod .digt-prod-container .el-row .el-col div {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.1),  rgba(0, 0, 0, 0));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.digt-prod .digt-prod-container .el-row .el-col:hover > div {
  background-color: rgba(79, 209, 233, 0.75);
  background-image: none,
}

.digt-prod .digt-prod-container .el-row .el-col img {
    width: 58px;
    height: 58px;
    filter:invert(1);
    margin-top:8px;
}

.digt-prod .digt-prod-container .el-row .el-col p {
    font-size: 15px;
    font-weight: bold;
    margin-top: 32px;
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(1) {
  background-image: url("~@/assets/images/digt-prod-01.jpg");
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(2) {
  background-image: url("~@/assets/images/digt-prod-02.jpg");
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(3) {
  background-image: url("~@/assets/images/digt-prod-03.jpg");
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(4) {
  background-image: url("~@/assets/images/digt-prod-04.jpg");
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(5) {
  background-image: url("~@/assets/images/digt-prod-05.jpg");
}

.digt-prod .digt-prod-container .el-row .el-col:nth-child(6) {
  background-image: url("~@/assets/images/digt-prod-06.jpg");
}
</style>