<template>
  <div class="cantact" >
            <el-row>
                <el-col>
                    <i></i>
                    <div>
                        <p class="title">公司邮箱</p>
                        <p class="content">service@emakercn.com</p>
                    </div>
                </el-col >
            </el-row>
            <el-row>
                <el-col>
                    <i></i>
                    <div>
                        <p class="title">公司地址</p>
                        <p class="content">江苏省南京市江宁开发区苏源大道19号B4座</p>
                    </div>
                </el-col>
            </el-row>
        </div>
</template>

<script>

export default {

}
</script>

<style scoped>

.cantact {
    padding: 16px 20px 24px;
 }

 .cantact > .el-row {
    margin-top: 18px;
 }

 .el-row .el-col {
    display: flex;
 }

 .el-row .el-col i {
    width: 24px;
    height: 24px;
    display: block;
    content: '';
    margin-right: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
 }

  .el-row:nth-child(1) .el-col i{
    background-image: url("~@/assets/images/contact-us-icon01.png");
  }

  .el-row:nth-child(2) .el-col i{
    background-image: url("~@/assets/images/contact-us-icon03.png");
  }

 .el-row .el-col > div {
    flex: 1;
    align-items: center;
    min-width: 0;
    white-space: pre-wrap;
    word-break: break-all;
 }

 .el-row .el-col > div .title {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    width: 72px;
 }

 .el-row .el-col > div .content {
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-top: 8px;
 }

</style>