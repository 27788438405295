<template>
  <div class="title">
    <div class="title-container">
        {{ title }}
    </div>
  </div>
</template>

<script>
export default {
    name:'mTitle',
    props:['title']
}
</script>

<style scoped>
 .title .title-container {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
 }
</style>