<template>
  <div class="mb-swiper" id="mbSwiper">
    <van-swipe class="my-swipe" :autoplay="2000" indicator-color="white">
      <van-swipe-item>
        <div>
          <p>产业数字化解决方案提供商，</p>
          <p>助您事业成功</p>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div>
          <p>微粒化解耦数字中台架构，</p>
          <p>高效打通信息孤岛</p>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div>
          <p>深度融合 “OT+IT” 技术，</p>
          <p>简单易用＋高性价比</p>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div>
          <p>德国工业4.0体系赋能，</p>
          <p>共建未来工厂竞争优势</p>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {

}
</script>

<style >
.my-swipe {
  height: 280px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  position: relative;
}

.my-swipe .van-swipe-item:nth-child(1) {
  background-image: url('~@/assets/images/banner1.jpg');
}

.my-swipe .van-swipe-item:nth-child(2) {
  background-image: url('~@/assets/images/banner2.jpg');
}

.my-swipe .van-swipe-item:nth-child(3) {
  background-image: url('~@/assets/images/banner3.jpg');
}

.my-swipe .van-swipe-item:nth-child(4) {
  background-image: url('~@/assets/images/banner4.jpg');
}

.my-swipe .van-swipe-item > div {
 height: 100%;
 top: 0;
 background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2),  rgba(0, 0, 0, 0));
}

.my-swipe .van-swipe-item p {
  margin-left: 20px;
  line-height: 1.8;
}

.my-swipe .van-swipe-item p:nth-child(1) {
  margin-top: 140px;
}

.my-swipe .van-swipe__indicators .van-swipe__indicator {
  background-color: #fff;
  border-radius: 0;
  width: 20px;
  line-height: 1.5;
  height: 2px;
  opacity: 1;
}

.my-swipe .van-swipe__indicators .van-swipe__indicator--active {
  background-color: rgb(79, 209, 233) !important;
}
</style>